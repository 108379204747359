import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './Header.css';
import logo from '../assets/logo1.png';

const Header = () => {
    return (
        <Navbar expand="lg" className="header">
            <Navbar.Brand href="/" className="d-flex align-items-center">
                <img src={logo} alt="Baby Chitan Logo" className="logo" />
                <span className="brand-name">Baby Chitan</span>
            </Navbar.Brand>
            <Nav className="ml-auto">
                <Nav.Link href="/">Home</Nav.Link>
            </Nav>
        </Navbar>
    );
};

export default Header;
