import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Home.css';
import logo from '../assets/logo.png';
import partner1 from '../assets/partner1.jpg';
import partner2 from '../assets/partner2.jpg';

const Home = () => {
    const [currentPartner, setCurrentPartner] = useState(partner1);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentPartner((prevPartner) => (prevPartner === partner1 ? partner2 : partner1));
        }, 2000); // Chuyển đổi mỗi 2 giây
        return () => clearInterval(interval);
    }, []);

    return (
        <Container className="home text-center">
            <Row className="intro">
                <Col>
                    <img src={logo} alt="Baby Chitan Logo" className="logo mb-4" />
                    <h2>Welcome to Baby Chitan</h2>
                    <div className="text-box">
                        <p>Baby Chitan is a new meme coin, aimed at bringing laughter and profits to the community.</p>
                        <p>With strong growth and an expanding community, Baby Chitan is becoming one of the most notable meme coins.</p>
                    </div>
                    <a href="https://blockchainminer.org/" className="buy-link" target="_blank" rel="noopener noreferrer">Buy $babychitan</a>
                </Col>
            </Row>
            <Row className="story mt-5">
                <Col>
                    <div className="text-box">
                        <h3>The Journey of Baby Chitan</h3>
                        <p>$babychitan, the lost child of $chitan, is on a journey to find its parents.</p>
                        <p>$babychitan has a hobby of cosplaying anime characters. This interesting hobby accompanies it on the journey to find its parents.</p>
                        <p>One day, Baby Chitan dressed up as Naruto, the famous ninja, and embarked on an adventure to the mountains to seek clues about its parents. On another day, it transformed into Goku and explored the dense forests, hoping to find a lead. The journey is long and arduous, but Baby Chitan's determination and love for cosplay keep it going.</p>
                    </div>
                </Col>
            </Row>
            <Row className="partners mt-5">
                <Col>
                    <h3>Our Partners</h3>
                    <Row className="partner-logos">
                        <Col>
                            <img src={currentPartner} alt="Partner" className="partner-logo" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Home;
