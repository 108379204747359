import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Telegram, Twitter } from 'react-bootstrap-icons';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer bg-dark text-white mt-5 p-4">
            <Container>
                <Row>
                    <Col md="12" className="text-center">
                        <div className="social-icons mb-3">
                            <a href="https://blockchainminer.org/" className="social-link" target="_blank" rel="noopener noreferrer">
                                <Telegram size={30} />
                            </a>
                            <a href="https://blockchainminer.org/" className="social-link" target="_blank" rel="noopener noreferrer">
                                <Twitter size={30} />
                            </a>
                        </div>
                        <p>&copy; 2024 Baby Chitan. All rights reserved.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
